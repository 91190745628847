import _pickNonNull2 from "../../pick-non-null.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.create = create;
exports.remove = remove;
exports.get = get;
exports.update = update;
exports.getAllLinkedAddons = getAllLinkedAddons;
exports.linkAddon = linkAddon;
exports.getAllEnvVarsForAddons = getAllEnvVarsForAddons;
exports.unlinkAddon = unlinkAddon;
exports.setBranch = setBranch;
exports.getAllBranches = getAllBranches;
exports.setBuildInstanceFlavor = setBuildInstanceFlavor;
exports.getAllDependencies = getAllDependencies;
exports.getAllEnvVarsForDependencies = getAllEnvVarsForDependencies;
exports.removeDependency = removeDependency;
exports.addDependency = addDependency;
exports.todo_getApplicationDependentsByOrgaAndAppId = todo_getApplicationDependentsByOrgaAndAppId;
exports.getAllDeployments = getAllDeployments;
exports.getDeployment = getDeployment;
exports.cancelDeployment = cancelDeployment;
exports.getAllEnvVars = getAllEnvVars;
exports.updateAllEnvVars = updateAllEnvVars;
exports.removeEnvVar = removeEnvVar;
exports.updateEnvVar = updateEnvVar;
exports.getAllExposedEnvVars = getAllExposedEnvVars;
exports.updateAllExposedEnvVars = updateAllExposedEnvVars;
exports.undeploy = undeploy;
exports.getAllInstances = getAllInstances;
exports.redeploy = redeploy;
exports.getInstance = getInstance;
exports.getAllTags = getAllTags;
exports.removeTag = removeTag;
exports.updateTag = updateTag;
exports.getTcpRedirs = getTcpRedirs;
exports.addTcpRedir = addTcpRedir;
exports.removeTcpRedir = removeTcpRedir;
exports.getAllDomains = getAllDomains;
exports.unmarkFavouriteDomain = unmarkFavouriteDomain;
exports.getFavouriteDomain = getFavouriteDomain;
exports.markFavouriteDomain = markFavouriteDomain;
exports.removeDomain = removeDomain;
exports.addDomain = addDomain;
exports.todo_getSelfApplicationDependents = todo_getSelfApplicationDependents;
var _pickNonNull = _pickNonNull2;
/**
 * GET /organisations/{id}/applications
 * GET /self/applications
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.instanceId
 */

function getAll(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["instanceId"]) // no body

  });
}
/**
 * POST /organisations/{id}/applications
 * POST /self/applications
 * @param {Object} params
 * @param {String} params.id
 * @param {Object} body
 */


function create(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "post",
    url: `/v2${urlBase}/applications`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}
 * DELETE /self/applications/{appId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function remove(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}
 * GET /self/applications/{appId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function get(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}
 * PUT /self/applications/{appId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function update(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /organisations/{id}/applications/{appId}/addons
 * GET /self/applications/{appId}/addons
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllLinkedAddons(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/addons`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * POST /organisations/{id}/applications/{appId}/addons
 * POST /self/applications/{appId}/addons
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function linkAddon(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "post",
    url: `/v2${urlBase}/applications/${params.appId}/addons`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /organisations/{id}/applications/{appId}/addons/env
 * GET /self/applications/{appId}/addons/env
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllEnvVarsForAddons(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/addons/env`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/addons/{addonId}
 * DELETE /self/applications/{appId}/addons/{addonId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.addonId
 */


function unlinkAddon(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/addons/${params.addonId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/branch
 * PUT /self/applications/{appId}/branch
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function setBranch(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/branch`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /organisations/{id}/applications/{appId}/branches
 * GET /self/applications/{appId}/branches
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllBranches(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/branches`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/buildflavor
 * PUT /self/applications/{appId}/buildflavor
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function setBuildInstanceFlavor(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/buildflavor`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /organisations/{id}/applications/{appId}/dependencies
 * GET /self/applications/{appId}/dependencies
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllDependencies(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/dependencies`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/dependencies/env
 * GET /self/applications/{appId}/dependencies/env
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllEnvVarsForDependencies(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/dependencies/env`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/dependencies/{dependencyId}
 * DELETE /self/applications/{appId}/dependencies/{dependencyId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.dependencyId
 */


function removeDependency(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/dependencies/${params.dependencyId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/dependencies/{dependencyId}
 * PUT /self/applications/{appId}/dependencies/{dependencyId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.dependencyId
 */


function addDependency(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/dependencies/${params.dependencyId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/dependents
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function todo_getApplicationDependentsByOrgaAndAppId(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/organisations/${params.id}/applications/${params.appId}/dependents`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/deployments
 * GET /self/applications/{appId}/deployments
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.limit
 * @param {String} params.offset
 * @param {String} params.action
 */


function getAllDeployments(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/deployments`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["limit", "offset", "action"]) // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/deployments/{deploymentId}
 * GET /self/applications/{appId}/deployments/{deploymentId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.deploymentId
 */


function getDeployment(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/deployments/${params.deploymentId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/deployments/{deploymentId}/instances
 * DELETE /self/applications/{appId}/deployments/{deploymentId}/instances
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.deploymentId
 */


function cancelDeployment(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/deployments/${params.deploymentId}/instances`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/env
 * GET /self/applications/{appId}/env
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllEnvVars(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/env`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/env
 * PUT /self/applications/{appId}/env
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function updateAllEnvVars(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/env`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/env/{envName}
 * DELETE /self/applications/{appId}/env/{envName}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.envName
 */


function removeEnvVar(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/env/${params.envName}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/env/{envName}
 * PUT /self/applications/{appId}/env/{envName}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.envName
 * @param {Object} body
 */


function updateEnvVar(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/env/${params.envName}`,
    headers: {
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /organisations/{id}/applications/{appId}/exposed_env
 * GET /self/applications/{appId}/exposed_env
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllExposedEnvVars(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/exposed_env`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/exposed_env
 * PUT /self/applications/{appId}/exposed_env
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function updateAllExposedEnvVars(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/exposed_env`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/instances
 * DELETE /self/applications/{appId}/instances
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function undeploy(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/instances`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/instances
 * GET /self/applications/{appId}/instances
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.deploymentId
 * @param {String} params.withDeleted
 */


function getAllInstances(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/instances`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["deploymentId", "withDeleted"]) // no body

  });
}
/**
 * POST /organisations/{id}/applications/{appId}/instances
 * POST /self/applications/{appId}/instances
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.commit
 * @param {String} params.useCache
 */


function redeploy(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "post",
    url: `/v2${urlBase}/applications/${params.appId}/instances`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["commit", "useCache"]) // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/instances/{instanceId}
 * GET /self/applications/{appId}/instances/{instanceId}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.instanceId
 */


function getInstance(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/instances/${params.instanceId}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/tags
 * GET /self/applications/{appId}/tags
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllTags(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/tags`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/tags/{tag}
 * DELETE /self/applications/{appId}/tags/{tag}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.tag
 */


function removeTag(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/tags/${params.tag}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/tags/{tag}
 * PUT /self/applications/{appId}/tags/{tag}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.tag
 */


function updateTag(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/tags/${params.tag}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/tcpRedirs
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getTcpRedirs(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/organisations/${params.id}/applications/${params.appId}/tcpRedirs`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * POST /organisations/{id}/applications/{appId}/tcpRedirs
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.payment
 * @param {Object} body
 */


function addTcpRedir(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "post",
    url: `/v2/organisations/${params.id}/applications/${params.appId}/tcpRedirs`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["payment"]),
    body
  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/tcpRedirs/{sourcePort}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.sourcePort
 * @param {String} params.namespace
 */


function removeTcpRedir(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "delete",
    url: `/v2/organisations/${params.id}/applications/${params.appId}/tcpRedirs/${params.sourcePort}`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["namespace"]) // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/vhosts
 * GET /self/applications/{appId}/vhosts
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getAllDomains(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/vhosts`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/vhosts/favourite
 * DELETE /self/applications/{appId}/vhosts/favourite
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function unmarkFavouriteDomain(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/vhosts/favourite`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /organisations/{id}/applications/{appId}/vhosts/favourite
 * GET /self/applications/{appId}/vhosts/favourite
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 */


function getFavouriteDomain(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "get",
    url: `/v2${urlBase}/applications/${params.appId}/vhosts/favourite`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/vhosts/favourite
 * PUT /self/applications/{appId}/vhosts/favourite
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {Object} body
 */


function markFavouriteDomain(params, body) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/vhosts/favourite`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * DELETE /organisations/{id}/applications/{appId}/vhosts/{domain}
 * DELETE /self/applications/{appId}/vhosts/{domain}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.domain
 */


function removeDomain(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "delete",
    url: `/v2${urlBase}/applications/${params.appId}/vhosts/${params.domain}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /organisations/{id}/applications/{appId}/vhosts/{domain}
 * PUT /self/applications/{appId}/vhosts/{domain}
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.appId
 * @param {String} params.domain
 */


function addDomain(params) {
  const urlBase = params.id == null ? "/self" : `/organisations/${params.id}`;
  return Promise.resolve({
    method: "put",
    url: `/v2${urlBase}/applications/${params.appId}/vhosts/${params.domain}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /self/applications/{appId}/dependents
 * @param {Object} params
 * @param {String} params.appId
 */


function todo_getSelfApplicationDependents(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/applications/${params.appId}/dependents`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}

export default exports;
export const __esModule = exports.__esModule;
const _getAll = exports.getAll,
      _create = exports.create,
      _remove = exports.remove,
      _get = exports.get,
      _update = exports.update,
      _getAllLinkedAddons = exports.getAllLinkedAddons,
      _linkAddon = exports.linkAddon,
      _getAllEnvVarsForAddons = exports.getAllEnvVarsForAddons,
      _unlinkAddon = exports.unlinkAddon,
      _setBranch = exports.setBranch,
      _getAllBranches = exports.getAllBranches,
      _setBuildInstanceFlavor = exports.setBuildInstanceFlavor,
      _getAllDependencies = exports.getAllDependencies,
      _getAllEnvVarsForDependencies = exports.getAllEnvVarsForDependencies,
      _removeDependency = exports.removeDependency,
      _addDependency = exports.addDependency,
      _todo_getApplicationDependentsByOrgaAndAppId = exports.todo_getApplicationDependentsByOrgaAndAppId,
      _getAllDeployments = exports.getAllDeployments,
      _getDeployment = exports.getDeployment,
      _cancelDeployment = exports.cancelDeployment,
      _getAllEnvVars = exports.getAllEnvVars,
      _updateAllEnvVars = exports.updateAllEnvVars,
      _removeEnvVar = exports.removeEnvVar,
      _updateEnvVar = exports.updateEnvVar,
      _getAllExposedEnvVars = exports.getAllExposedEnvVars,
      _updateAllExposedEnvVars = exports.updateAllExposedEnvVars,
      _undeploy = exports.undeploy,
      _getAllInstances = exports.getAllInstances,
      _redeploy = exports.redeploy,
      _getInstance = exports.getInstance,
      _getAllTags = exports.getAllTags,
      _removeTag = exports.removeTag,
      _updateTag = exports.updateTag,
      _getTcpRedirs = exports.getTcpRedirs,
      _addTcpRedir = exports.addTcpRedir,
      _removeTcpRedir = exports.removeTcpRedir,
      _getAllDomains = exports.getAllDomains,
      _unmarkFavouriteDomain = exports.unmarkFavouriteDomain,
      _getFavouriteDomain = exports.getFavouriteDomain,
      _markFavouriteDomain = exports.markFavouriteDomain,
      _removeDomain = exports.removeDomain,
      _addDomain = exports.addDomain,
      _todo_getSelfApplicationDependents = exports.todo_getSelfApplicationDependents;
export { _getAll as getAll, _create as create, _remove as remove, _get as get, _update as update, _getAllLinkedAddons as getAllLinkedAddons, _linkAddon as linkAddon, _getAllEnvVarsForAddons as getAllEnvVarsForAddons, _unlinkAddon as unlinkAddon, _setBranch as setBranch, _getAllBranches as getAllBranches, _setBuildInstanceFlavor as setBuildInstanceFlavor, _getAllDependencies as getAllDependencies, _getAllEnvVarsForDependencies as getAllEnvVarsForDependencies, _removeDependency as removeDependency, _addDependency as addDependency, _todo_getApplicationDependentsByOrgaAndAppId as todo_getApplicationDependentsByOrgaAndAppId, _getAllDeployments as getAllDeployments, _getDeployment as getDeployment, _cancelDeployment as cancelDeployment, _getAllEnvVars as getAllEnvVars, _updateAllEnvVars as updateAllEnvVars, _removeEnvVar as removeEnvVar, _updateEnvVar as updateEnvVar, _getAllExposedEnvVars as getAllExposedEnvVars, _updateAllExposedEnvVars as updateAllExposedEnvVars, _undeploy as undeploy, _getAllInstances as getAllInstances, _redeploy as redeploy, _getInstance as getInstance, _getAllTags as getAllTags, _removeTag as removeTag, _updateTag as updateTag, _getTcpRedirs as getTcpRedirs, _addTcpRedir as addTcpRedir, _removeTcpRedir as removeTcpRedir, _getAllDomains as getAllDomains, _unmarkFavouriteDomain as unmarkFavouriteDomain, _getFavouriteDomain as getFavouriteDomain, _markFavouriteDomain as markFavouriteDomain, _removeDomain as removeDomain, _addDomain as addDomain, _todo_getSelfApplicationDependents as todo_getSelfApplicationDependents };